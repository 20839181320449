<template>
  <v-container fluid>
    <v-card raised>
      <v-progress-linear
        indeterminate
        color="trukkin-theme darken-2"
        v-show="loading"
      ></v-progress-linear>
      <v-card-title primary-title class="bg-clr">
        <v-btn fab flat icon class="nospace" @click.native="goback">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span class="headline">
          &nbsp;Create Mapping
          {{ this.mappingTypeMap[this.mappingType].title }}
        </span>
      </v-card-title>
      <v-tabs centered vertical color="orange" dark icons-and-text>
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <v-tab href="#tab-1">
          Assigned Roles to User Type
          <v-icon>rowing</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          All Roles
          <v-icon>supervised_user_circle</v-icon>
        </v-tab>

        <v-tab-item :key="1" :value="'tab-1'">
          <v-card flat>
            <v-container
              fluid
              class="p-t-20"
              bg
              fill-height
              grid-list-md
              text-xs-center
              v-if="this.mappingData.userType"
            >
              <v-layout row wrap align-center>
                <v-flex md12 class="communication">
                  <v-card class="nospace"></v-card>
                  <span v-if="this.mappingData.userType" class="mapping">
                    User Type:
                    {{ this.mappingData.userType.userTypeTitle }}
                  </span>
                  <v-flex>
                    <v-container
                      fluid
                      bg
                      fill-height
                      grid-list-md
                      text-xs-left
                      v-if="this.mappingData.userType"
                    >
                      <v-layout wrap>
                        <v-flex md11 style="margin:auto;">
                          <v-layout
                            wrap
                            :key="roles._id"
                            v-for="roles in rolesItems"
                          >
                            <v-flex
                              md12
                              v-if="roles.children && roles.children.length > 0"
                            >
                              <h1>{{ roles.role.roleTitle }}</h1>
                              <v-switch
                                :key="j"
                                color="green"
                                class="switch-permission-type"
                                v-for="(data, j) in roles.children"
                                v-model="data.isChecked"
                                :label="` ${data.operationTitle.toString()}`"
                                @change="updateRole(data, 'sub-role', roles)"
                              ></v-switch>
                            </v-flex>

                            <!-- <v-flex v-else>No SubRole Defined for {{roles.title}}</v-flex> -->
                          </v-layout>
                          <v-layout class="not-found" v-if="!rolesItems.length">
                            <v-flex md12>
                              <h1>No roles were found</h1>
                              <p>
                                Please click on "All Roles" Section to add roles
                              </p>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-flex> </v-flex
                >*
                <span style="color: green">Green</span>
                <span>&nbsp;</span> Represent the value is set and Grey
                Represent value is not set.
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-2'">
          <v-card flat>
            <v-container
              class="p-t-20"
              bg
              fill-height
              grid-list-md
              text-xs-center
              v-if="this.mappingData.userType"
            >
              <v-layout row wrap align-center>
                <v-flex md12 class="communication">
                  <v-card class="nospace"></v-card>
                  <span v-if="this.mappingData.userType" class="mapping">
                    User Type:
                    {{ this.mappingData.userType.userTypeTitle }}
                  </span>
                  <v-flex>
                    <v-switch
                      :key="i"
                      :readonly="loading"
                      color="green"
                      class="switch-permission-type"
                      v-for="(data, i) in this.allRoles.data"
                      v-model="data.isChecked"
                      :label="` ${data.roleTitle.toString()}`"
                      v-on:change="updateRole(data, 'role', 0)"
                    ></v-switch>
                  </v-flex> </v-flex
                >*
                <span style="color: green">Green</span>
                <span>&nbsp;</span> Represent the value is set and Grey
                Represent value is not set.
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import _ from "lodash";
import {
  getAllRoles,
  getAllOperations,
  assignOperationtoRole,
  assignRoletoUserType,
} from "../../constants/api-urls";
export default {
  created() {
    this.mappingType = this.$route.params.mappingType;
    this.id = this.$route.params.id;
    this.getRoles(this.id, "user-role");
    this.getAllRoles(this.id, this.mappingType);
  },
  watch: {
    selection(newValue) {},
  },
  data() {
    return {
      id: "",
      loading: false,
      selection: [],
      dense: false,
      selectable: false,
      activatable: false,
      hoverable: false,
      openOnClick: false,
      shaped: false,
      rounded: false,
      color: "primary",
      selectedColor: "accent",
      selectedColors: ["accent", "teal", "red", "success", "warning lighten-2"],
      activeOperations: [],

      assignedRole: [],
      assignedSubRole: [],
      switch1: true,
      selectable: true,
      mappingData: [],
      rolesItems: [],
      allRoles: [],
      subRoleItems: [],
      mappingType: "",
      mappingTypeMap: {
        "user-role": {
          name: "user-role",
          title: "User - Role Mapping",
        },
        "role-sub-role": {
          name: "role-sub-role",
          title: "Role - Sub Role Mapping",
        },
      },
      headers: [
        {
          text: "Sr No",
          sortable: false,
          value: "srno",
          class: "table-header-item",
        },
        {
          text: "Type",
          sortable: false,
          class: "table-header-item",
          value: "user-type",
        },
        {
          text: "Status",
          sortable: false,
          class: "table-header-item",
          value: "status",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          class: "table-header-item",
        },
      ],
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async showMapping(id, mappingType) {
      let url = "";
      if (mappingType == "user-role") {
        url = `/assignedRoletoUserType/${id}`;
      }
      if (mappingType == "role-sub-role") {
        url = `/assignedSubRoletoRole/${id}`;
      }

      this.loading = true;

      delete this.axios.defaults.headers.common["token"];

      const response = await this.axios.get(this.constants.rbacUrl + url);
      if (response.status == 200) {
        return response.data.data;
      } else {
        this.loading = false;
      }
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    async getRoles(id, mappingType) {
      this.loading = true;
      let url = getAllRoles;
      delete this.axios.defaults.headers.common["token"];

      let res = await this.axios.get(this.constants.rbacUrl + url);
      let data = await this.showMapping(id, mappingType);
      this.mappingData = data;

      let roles = [];
      if (data) {
        roles = data.roles.map((x) => x.role._id);
      }
      this.assignedRole = roles;
      roles.forEach((d1) => {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i]._id == d1) {
            res.data.data[i].isChecked = true;
          }
        }
      });
      this.allRoles = res.data;
    },
    async getAllRoles(id, mappingType) {
      if (mappingType == "user-role") {
        this.loading = true;
        let data = await this.showMapping(id, mappingType);
        this.mappingData = data;
        this.rolesItems = data.roles;
        let __roles__ = [];
        await this.asyncForEach(this.rolesItems, async (role, index) => {
          let operations = [];
          let children = await this.showMapping(role.role._id, "role-sub-role");
          await this.asyncForEach(children.subRole, async (subRole, i) => {
            await this.asyncForEach(role.operations, async (activeSubRole) => {
              if (subRole._id == activeSubRole._id) {
                children.subRole[i].isChecked = true;
              }
            });
          });
          operations = children.subRole;
          let obj = {
            userType: data.userType,
            role: role.role,
            children: operations,
          };
          __roles__.push(obj);
        });
        this.rolesItems = __roles__;
        this.loading = false;
      }
      if (mappingType == "role-sub-role") {
        let finalRes = [];

        this.loading = true;
        let url = getAllOperations;
        delete this.axios.defaults.headers.common["token"];

        let res = await this.axios.get(this.constants.rbacUrl + url);
        let data = await this.showMapping(id, mappingType);
        this.mappingData = data;
        let subRoles = data.subRole.map((x) => x._id);
        this.assignedSubRole = subRoles;
        subRoles.forEach((d1) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i]._id == d1) {
              res.data.data[i].isChecked = true;
            }
          }
        });
        this.subRoleItems = res.data;
      }
    },
    async updateRole(data, type, _roles) {
      this.loading = true;
      let roles = this.assignedRole;
      let obj = [];
      roles = [...new Set(roles)];

      if (!roles) {
        let _o = {
          roleId: data._id,
          operations: [],
        };

        obj.push(_o);
      } else {
        if (type == "role") {
          let done = true;

          if (this.rolesItems.length == 0) {
            let _o = {
              roleId: data._id,
              operations: [],
            };

            obj.push(_o);
          } else {
            await this.asyncForEach(this.rolesItems, async (_data) => {
              let subRoles = _data.children.filter((e) => e.isChecked == true);
              subRoles = subRoles.map((x) => x._id);
              let _o = {
                roleId: _data.role._id,
                operations: subRoles,
              };
              obj.push(_o);

              if (data.isChecked && done) {
                _o = {
                  roleId: data._id,
                  operations: [],
                };

                done = false;
                obj.push(_o);
              }
              if (!data.isChecked) {
                obj = obj.filter((x) => x.roleId != data._id);
              }
            });
          }
        } else {
          let done = true;
          await this.asyncForEach(this.rolesItems, async (_data) => {
            let subRoles = _data.children.filter((e) => e.isChecked == true);

            subRoles = subRoles.map((x) => x._id);
            let _o = {};
            if (_roles.role._id == _data.role._id) {
              if (data.isChecked && done) {
                subRoles.push(data._id);
                subRoles = [...new Set(subRoles)];
                _o = {
                  roleId: _roles.role._id,
                  operations: subRoles,
                };
                done = false;
              } else {
                _o = {
                  roleId: _data.role._id,
                  operations: subRoles,
                };
              }
            } else {
              _o = {
                roleId: _data.role._id,
                operations: subRoles,
              };
            }
            if (!_.isEmpty(_o)) {
              obj.push(_o);
            }
          });
        }
      }
      let body = {
        userTypeId: this.$route.params.id,
        roles: obj,
      };
      let url = assignRoletoUserType;
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          if (type == "role") {
            this.getAllRoles(this.id, this.$route.params.mappingType);
          }

          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Assign";
          this.loading = false;
        }
      );
    },
    async assignOperationToRole(roles, data) {
      let id = data._id;
      this.loading = true;
      let __operations = roles.children.map(({ _id }) => ({
        _id,
      }));
      let operations = [];
      await this.asyncForEach(__operations, async (operation) => {
        operations.push(operation._id);
      });

      let __assignedoperations = roles.active.map(({ _id }) => ({
        _id,
      }));
      let assignedOperations = [];
      await this.asyncForEach(__assignedoperations, async (operation) => {
        assignedOperations.push(operation._id);
      });
      if (data.isChecked) {
        assignedOperations.push(data._id);
      } else {
        assignedOperations = assignedOperations.filter((e) => e !== id);
      }

      // if (data.isChecked) {
      //     operations.push(data._id);
      // } else {
      //     var index = operations.indexOf(data._id);
      //     if (index > -1) {
      //         operations.splice(index, 1);
      //     }
      // }
      let body = {
        roleId: roles._id,
        operations,
        assignedOperations,
      };
      let url = assignOperationtoRole;
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.getAllRoles(
            this.$route.params.id,
            this.$route.params.mappingType
          );
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Assign SubRole";
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.tree {
  text-align: left;
}
.mapping {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}
.switch-permission-type {
  width: 25%;
  display: inline-block;
  float: left;
  margin-bottom: 12px;
  word-break: break-all;
  padding-right: 12px;
  height: 40px;
  display: flex;
  align-items: flex-start;
}
.not-found {
  text-align: center !important;
  margin: 100px !important;
}
.not-found h1 {
  color: #b8b8b8;
}
.p-t-20 {
  padding-top: 20px !important;
}
</style>
